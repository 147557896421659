import instagram from '../assets/instagram.png'
import youtube from '../assets/youtube.jpeg'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import linkedin from '../assets/linkedin.png'
import photography from '../assets/photography.png'
import web from '../assets/website.png'
import discord from '../assets/discord.png'
import book1 from '../assets/book1.png'
import book2 from '../assets/book2.png'

const items = [
    {
        "title": "Blog",
        "subtitle": "Check out my blog!",
        "image": photography,
        "link": "https://blog.295devops.com" //your personal website or portfolio  link
    },
    {
        "title": "GitHub",
        "subtitle": "@roxsross | 🏠 of my open-source projects",
        "image": github,
        "link": "https://github.com/roxsross" //Github Profile link
    },
    {
        "title": "Linkedin",
        "subtitle": "@roxsross | Connect with me",
        "image": linkedin,
        "link": "https://linkedin.com/in/roxsross/" //Github Profile link
    },
    {
        "title": "Instagram",
        "subtitle": "@roxsross | Hub of my awesome 😎 pics",
        "image": instagram,
        "link": "https://instagram.com/roxsross" //instagram profile link 
    },
    {
        "title": "YouTube",
        "subtitle": "@295devops | Official channel of mine",
        "image": youtube,
        "link": "https://youtube.com/channel/UCa-FcaB75ZtqWd1YCWW6INQ"//youtube channel link 
    },
    {
        "title": "Telegram",
        "subtitle": "@roxsross | Chat with me instantly, Don't hesitate! ",
        "image": telegram,
        "link": "https://telegram.me/roxsross" //Telegram Pofile 
    },
    {
        "title": "IV Edicion Bootcamp DevOps",
        "subtitle": "@roxsross | Challenges! ",
        "image": web,
        "link": "https://reto.295devops.com" //Telegram Pofile 
    },
    {
        "title": "Canal de Discord",
        "subtitle": "@295devops | Discord! ",
        "image": discord,
        "link": "https://discord.gg/RWQjCRaVJ3" //Telegram Pofile 
    },
    {
        "title": "The Phoenix Project",
        "subtitle": "@BookPhoenix | The Phoenix Project! ",
        "image": book1,
        "link": "https://drive.google.com/drive/folders/1MmJJgpLIs1FtV42h5hyz8qUE5s2vhkx8" //Telegram Pofile 
    },
    {
        "title": "The Devops Handbook",
        "subtitle": "@BookHandbook | The Devops Handbook! ",
        "image": book2,
        "link": "https://drive.google.com/drive/folders/1oaSLgQQPdmSWDOvvQI7zY5BjL7nmEzM9" //Telegram Pofile 
    } 

    ]

export default items
